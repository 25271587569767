import Service from "./Service";
const baseurl = '/api/historia-remision';
export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showRemisionByHc(id_historia) {
        return Service.get(`${baseurl}/show-remision-hc/${id_historia}`);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    print(id,id_remision) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/print/${id}/${id_remision}`;
    },
}