<template>
    <div class="modal fade" id="modalOrden" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ordenes Clinicas</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row text-center">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <tr class="table-primary">
                                    <th>#</th>
                                    <th>Fecha</th>
                                    <th></th>
                                </tr>
                                <tr v-if="!ordenes.length" >
                                    <td colspan="3">
                                        <div class="alert alert-warning" >
                                            No se han registrado ordenes medicas...
                                        </div>
                                    </td>
                                </tr>
                                <tr v-for="or in ordenes" :key="or.id">
                                    <td>{{or.id}}</td>
                                    <td>{{or.historia.fecha}}</td>
                                    <td>
                                        <div class="form-row">
                                            <div class="form-group px-2">
                                                <button @click="verOrden(or.id)" class="btn btn-outline-primary lift" >
                                                    <i class="fa fa-eye fa-fw"></i>&nbsp;Ver orden
                                                </button>
                                            </div>
                                            <div class="form-group px-2">
                                                <button @click="eliminarOrden(or.id)" class="btn btn-outline-danger lift" >
                                                    <i class="fa fa-trash-alt"></i>&nbsp;Eliminar orden
                                                </button>
                                            </div>
                                        </div>
                                        
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                </div>
                </div>
            </div>
        </div>
</template>
<script>
import ordenClinicaService from "../../../../services/ordenClinicaService";
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
export default {
    props: ['ordenes','usuario'],
    methods:{
        async eliminarOrden(id) {
            this.LoaderSpinnerShow();
            await ordenClinicaService.delete(id);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'success',
                title: 'Datos eliminados con exito'
            });
            $('#modalOrden').modal('hide');
        },
        async verOrden(id) {
            $('#modalOrden').modal('hide');
            this.$router.push({name:'orden.clinica',params:{'id_orden':id,'id_usuario':this.usuario.id}});
        }
    }
}
</script>
