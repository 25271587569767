import Service from "./Service";
const baseurl = '/api/historia-recomendacion';

export default {
    index(params={}) {
        return Service.get(`${baseurl}`,{
            params: params
        });
    },
    store(data){
        return Service.post(`${baseurl}/store`,data);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    showByHc(id_historia) {
        return Service.get(`${baseurl}/show-by-hc/${id_historia}`);
    },
}