<template>
    <main>
        <div class="form-group row" v-for="item in $v.partes_fisicas.$each.$iter" :key="item.id">
            <label  class="col-sm-6 col-form-label"> {{item.nombre.$model}} </label>
            <div class="col-sm-6">
                <textarea class="form-control" v-model="item.detalle.$model" :class="{'is-invalid':item.detalle.$error}"></textarea>
            </div>
        </div>
    </main>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import partesFisicasService from "../../../../services/partesFisicasService";
import historiaExploracionFisicaService from "../../../../services/historiaExploracionFisicaService";
import {isEmpty} from 'lodash';
export default {
    props:['idHistoria','finalizada'],
    data(){
        return {
            partes_fisicas:[],
        }
    },
    validations (){
        return {
            partes_fisicas:{
                $each:{
                    nombre:{required},
                    detalle:{required}
                }
            }
        }
    },
    methods:{
        retornar(){
            this.$v.$touch();
            if(this.$v.$invalid) return false;
            let exploracion ={
                id_historia:this.idHistoria,
                partes:this.partes_fisicas,
            }
            return exploracion;
        },
        async cargarPartesFisicas(){
            const response = await partesFisicasService.index();
            this.partes_fisicas= response.data.map(x=>{
                return {
                    ...x,
                    detalle:''
                }
            });
        },
    },
    watch:{
        finalizada: async function (value){
            if(value==='N'){
                 const response = await historiaExploracionFisicaService.showByHc(this.idHistoria);
                if(!isEmpty(response.data)){
                     response.data.forEach(x=>{
                        let i = this.partes_fisicas.findIndex(p => parseInt(p.id) === parseInt(x.id_parte_fisica));
                        if(i!==-1)  this.partes_fisicas[i].detalle = x.detalle;
                    });
                    
                }
            } 
        }
    },
    async created(){
        this.cargarPartesFisicas();
        if(this.finalizada==='N'){
            const response = await historiaExploracionFisicaService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                     response.data.forEach(x=>{
                        let i = this.partes_fisicas.findIndex(p => parseInt(p.id) === parseInt(x.id_parte_fisica));
                        if(i!==-1)  this.partes_fisicas[i].detalle = x.detalle;
                    });
                    
                }
        }
    }
}
</script>