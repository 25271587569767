import Service from './Service';
import {isEmpty} from 'lodash';

const resource = '/api/tarifa';

export default {
    search(search = '', pagination = {}) {

        let url = `${resource}/search?`;

        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    show(id){
        const url = `${resource}/show/${id}`;
        return Service.get(url);
    },
    index(search = '', pagination = {}) {

        let url = `${resource}?`;

        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    store(tarifa){
        return Service.get(`${resource}/store`, tarifa);
    },
    fecthServices(search = '', pagination = {}, id_tarifa){

        let url = `${resource}/services?id_tarifa=${id_tarifa}`;

        url += search !== '' ? `&s=${search}` : '';

        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    saveService(service){
        return Service.post(`${resource}/services/store`, service);
    },
    updateService(service){
        return Service.put(`${resource}/services/update`, service);
    }
}
