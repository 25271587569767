<template>
    <div class="modal fade" id="modalFormula" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Formulas Medicas</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row text-center">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <tr class="table-primary">
                                    <th>#</th>
                                    <th>Observaciones</th>
                                    <th></th>
                                </tr>
                                <tr v-if="!formulas.length" >
                                    <td colspan="3">
                                        <div class="alert alert-warning" >
                                            No se han registrado formulas medicas...
                                        </div>
                                    </td>
                                </tr>
                                <tr v-for="form in formulas" :key="form.id">
                                    <td>{{form.id}}</td>
                                    <td>{{form.observaciones}}</td>
                                    <td>
                                        <div class="form-row">
                                            <div class="form-group px-2">
                                                <button @click="verFormula(form.id)" class="btn btn-outline-primary lift" >
                                                    <i class="fa fa-eye fa-fw"></i>&nbsp;Ver Formula
                                                </button>
                                            </div>
                                            <div class="form-group px-2">
                                                <button @click="eliminarFormula(form.id);" class="btn btn-outline-danger lift" >
                                                    <i class="fa fa-trash-alt"></i>&nbsp;Eliminar formula
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                </div>
                </div>
            </div>
        </div>
</template>
<script>
import formulaMedicaService from "../../../../services/formulaMedicaService";
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
export default {
    props: ['formulas','usuario'],
    methods:{
        async eliminarFormula(id) {
            this.LoaderSpinnerShow();
            await formulaMedicaService.delete(id);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'success',
                title: 'Datos eliminados con exito'
            });
            $('#modalFormula').modal('hide');
        },
        async verFormula(id) {
            $('#modalFormula').modal('hide');
            this.$router.push({name:'formula.clinica',params:{'id_formula':id,'id_usuario':this.usuario.id}});
        }
    }
}
</script>

