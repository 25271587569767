<template>
  <main>
    <div class="mt-2">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Riesgo Biopsicosocial</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Reporte Examenes </a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <index ref="escala" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario"></index>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <indexExamenes ref="examenes" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario"></indexExamenes>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import index from "../base/baseRiesgobiopsicosocial/index/index";
import indexExamenes from "../base/baseReporteExamenes/index/indexExamenes";
export default {
  props: ['idHistoria','finalizada','idUsuario'],
  components:{index,indexExamenes},
  methods : {
    async save(){
      await this.$refs.escala.save();
      await this.$refs.examenes.save();
    }
  }
}
</script>

