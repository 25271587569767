import Service from "./Service";
const baseurl = '/api/historia-intervencion';

export default {
    index(params={}) {
        return Service.get(`${baseurl}`,{
            params: params
        });
    },
    store(data){
        return Service.post(`${baseurl}/store`,data);
    }
}