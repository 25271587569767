import Service from './Service';
import {isEmpty} from 'lodash';

const resource = '/api/finalidad';

export default {
    getByCode(codigo) {
        let url = `${resource}/get?by=code&codigo=${codigo}`;
        return Service.get(url);
    },
    index(search = '', pagination = {}){

        let url = `${resource}?`;

        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    }
}
