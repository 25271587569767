<template>
  <main>
    <div class="form-row">
      <div class="col-12">
        <table class="table table-sm table-bordered">
          <thead>
          <tr>
            <th class="text-justify align-middle w-25">Vacuna</th>
            <th class="text-justify align-middle w-25">Dosis</th>
            <th class="text-justify align-middle w-25">Fecha Aplicación</th>
            <th class="text-justify align-middle w-25">Institucional</th>
          </tr>
          </thead>
        </table>
      </div>
      <div class="col-12" v-for="vacuna in vacunas" :key="vacuna.id">

        <table class="table table-sm table-bordered table-font-sm">
          <tr v-for="(dosis, i) in vacuna.dosis" :key="dosis.id">
            <td class="text-justify align-middle w-25" :rowspan="vacuna.dosis.length" v-if="i===0">{{vacuna.nombre}}</td>
            <td class="text-center align-middle w-25">{{dosis.nombre_dosis}}</td>
            <td class="w-25"><input type="date" class="form-control form-control-sm" v-model="dosis.fecha_aplicacion"></td>
            <td class="w-25">
              <select class="form-control form-control-sm" v-model="dosis.institucional">
                <option value="S">Sí</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row mt-2">
      <div class="table-responsive col-12">
        <table class="table table-striped">
          <tr class="table-primary">
            <th>Vacuna</th>
            <th>Dosis</th>
            <th>Fecha</th>
            <th>Observacion</th>
            <th>Institucional</th>
            <th></th>
          </tr>
          <tr v-for="item in items" :key="item.id" >
            <td>{{item.vacuna.nombre}}</td>
            <td>{{item.dosis.nombre_dosis}}</td>
            <td>{{ item.fecha }}</td>
            <td v-if="item.observacion===null">
              <span >Sin observacion</span>
            </td>
            <td v-else>{{ item.observacion }}</td>
            <td>{{ item.institucional }}</td>
            <td>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </main>
</template>
<script>
import usuarioService from '../../../../../services/usuarioService';
import usuarioVacunaDosisService from "../../../../../services/usuarioVacunaDosisService";
import Swal from "sweetalert2";
import embarazoService from '../../../../../services/embarazoService';
import vacunaService from '../../../../../services/vacunaService';
import {isEmpty} from "lodash";
import Toast from "@/components/common/utilities/toast";

import {required} from "vuelidate/lib/validators";

export default {
  props: ['idHistoria','finalizada','idUsuario'],
  data() {
    return {
      usuario: {},
      vacunas: [],
      items: [],
      item:{
        vacuna:{},
        dosis : {},
        observacion:'',
        institucional:'',
        fecha:''
      },
      embarazo_actual: {},
    };
  },
  validations (){
    return {
      item:{
        vacuna:{required},
        fecha:{required},
        institucional:{required},
        dosis:{required},
      },
    }
  },
  methods: {
    async cargarUsuario(id) {
      const response = await usuarioService.show(id);
      this.usuario = response.data;

      const res_embarazo_actual= await embarazoService.showByLast(id);
      this.embarazo_actual=res_embarazo_actual.data;
    },
    async cargarVacunas(){
      const response = await vacunaService.index({
        id_usuario : this.usuario.id,
        sexo : this.usuario.sexo,
        fecha_nacimiento : this.usuario.fecha_nacimiento,
        ...(!isEmpty(this.embarazo_actual) && this.embarazo_actual.activo === 'S' ? {gestante : 'S'} : {})
      });
      this.vacunas = response.data.map(x => {
        return {
          id : x.id,
          nombre : x.nombre,
          dosis : x.dosis.map(y => {
            return {
              ...y,
              institucional : '',
              fecha_aplicacion : ''
            }
          })
        }
      });
    },
    async save() {
      try {

        let vacunas_usuario = [];
        this.vacunas.filter(x => {
          return x.dosis.filter(y => y.fecha_aplicacion !== '' && y.institucional !== '').length
        }).forEach(x => {
          x.dosis.forEach(y => {

            if(y.institucional !== '' && y.fecha_aplicacion !== ''){

              const item = {
                dosis: {...y},
                observacion: '',
                institucional: y.institucional,
                fecha: y.fecha_aplicacion
              };

              vacunas_usuario.push({
                ...(!isEmpty(this.embarazo_actual) && this.embarazo_actual.activo === 'S' ? {gestante : 'S'} : {}),
                id_usuario:this.idUsuario,
                id_embarazo:!isEmpty(this.embarazo_actual) && this.embarazo_actual.activo === 'S'? this.embarazo_actual.id:null,
                dosis: item,
              });

            }

          });

        });


        this.LoaderSpinnerShow();

        await usuarioVacunaDosisService.store(vacunas_usuario);

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con exíto'
        });

      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Swal.fire("Ups!", "ha ocurrido un error al procesar la solicitud", "error");
      }
    },
    async cargarVacunasUsuario(){
      const response = await usuarioVacunaDosisService.showByIdUser(this.idUsuario);
      if(!isEmpty(response.data)){
        this.items = response.data.map(x=>{
          return {
            id:x.id,
            fecha:x.fecha,
            observacion:x.observaciones,
            institucional:x.institucional,
            vacuna:x.dosis.vacuna,
            dosis:x.dosis
          }
        });
      }
    },
    limpiarCampos(){
      this.item.fecha='';
          this.item.institucional=''
      this.item.observacion='',
          this.item.dosis={},
          this.item.vacuna={}
    },
    async eliminar(id) {
      try {
        this.LoaderSpinnerShow();
        await usuarioVacunaDosisService.delete(id);
        this.LoaderSpinnerHide();
        Swal.fire('Datos Eliminados con exito','', 'success');
        let i = this.items.findIndex(x=>x.id === id);
        this.items.splice(i, 1);
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Swal.fire("Ups!", "ha ocurrido un error al procesar la solicitud", "error");
      }
    },
  },
  async created() {
    this.LoaderSpinnerShow();
    await this.cargarUsuario(this.idUsuario);
    await this.cargarVacunas();
    await this.cargarVacunasUsuario();
    this.LoaderSpinnerHide();
  },
  computed : {
    dosis(){
      if(!isEmpty(this.item.vacuna)){
        return this.item.vacuna.dosis;
      }
      return [];
    }
  }
}
</script>

<style scoped>
.table-font-sm{
  font-size: 12px;
}
</style>
