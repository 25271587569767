<template>
  <div class="row">
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Ultima Menstruación</label>
      <input type="date" class="form-control" v-model="embarazo_actual.ultima_menstruacion" :class="{'is-invalid':$v.embarazo_actual.ultima_menstruacion.$error && $v.embarazo_actual.ultima_menstruacion.$dirty}">
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Semanas de gestación</label>
      <input type="number" class="form-control" v-model="embarazo_actual.semanas_gestacion" :class="{'is-invalid':$v.embarazo_actual.semanas_gestacion.$error && $v.embarazo_actual.semanas_gestacion.$dirty}">
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Fecha probable de parto</label>
      <input type="date" class="form-control" v-model="embarazo_actual.fecha_parto" :class="{'is-invalid':$v.embarazo_actual.fecha_parto.$error && $v.embarazo_actual.fecha_parto.$dirty}">
    </div>
  </div>
</template>
<script>
import embarazoActualService from "../../../../services/embarazoActualService";
import Toast from '../../../../components/common/utilities/toast';
import {required} from "vuelidate/lib/validators";
import {isEmpty} from "lodash";
import embarazoService from '../../../../services/embarazoService';
export default {
  props: ['idHistoria','finalizada','idUsuario'],
  data(){
    return {
      id_historia:'',
      embarazo_actual:{
        id:'',
        id_historia:'',
        id_embarazo:'',
        ultima_menstruacion:'',
        semanas_gestacion:'',
        fecha_parto:'',
      },
      embarazo:{}
    }
  },
  validations (){
    return {
      embarazo_actual: {
        ultima_menstruacion:{required},
        semanas_gestacion:{required},
        fecha_parto:{required},
      }
    }
  },
  methods: {
    async save(){
      try {
        this.$v.embarazo_actual.$touch();
        if(this.$v.embarazo_actual.$invalid) return;
        this.embarazo_actual.id_historia=this.idHistoria;
        this.embarazo_actual.id_embarazo= !isEmpty(this.embarazo) && this.embarazo.activo === 'S'?this.embarazo.id:null;
        this.LoaderSpinnerShow();
        if(this.id_historia!=='' && this.id_historia===this.idHistoria){
          await embarazoActualService.update(this.embarazo_actual);
        }else{
          await embarazoActualService.store(this.embarazo_actual);
        }

        this.LoaderSpinnerHide();
        Toast.fire({
            icon: 'success',
            title: 'Datos guardados con exito'
        });
      } catch (error) {
        console.error(error);
        this.LoaderSpinnerHide();
        Toast.fire({
            icon: 'error',
            title: 'Ocurrio un error al procesar la solicitud'
        });
            
      }
      
    },
  },
  watch:{
    finalizada: async function (value){

      if(value==='N'){
        const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
        this.embarazo=res_embarazo_actual.data;
        if(!isEmpty(res_embarazo_actual.data)){
          const response=await embarazoActualService.showByLast(this.embarazo.id);
          if(!isEmpty(response.data)){
            this.embarazo_actual.ultima_menstruacion=response.data.ultima_menstruacion;
            this.embarazo_actual.semanas_gestacion=response.data.semanas_gestacion;
            this.embarazo_actual.fecha_parto=response.data.fecha_parto;
            this.embarazo_actual.id_historia=response.data.id_historia;
            this.embarazo_actual.id_embarazo=response.data.id_embarazo;
            this.embarazo_actual.id=response.data.id;
            this.id_historia=response.data.id_historia;
          }
        }
      }
    },
  },
  async created(){

    if(this.finalizada==='N'){

      const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
      this.embarazo=res_embarazo_actual.data;

      if(!isEmpty(res_embarazo_actual.data)){
        const response=await embarazoActualService.showByLast(this.embarazo.id);
        if(!isEmpty(response.data)){
          this.embarazo_actual.ultima_menstruacion=response.data.ultima_menstruacion;
          this.embarazo_actual.semanas_gestacion=response.data.semanas_gestacion;
          this.embarazo_actual.fecha_parto=response.data.fecha_parto;
          this.embarazo_actual.id_historia=response.data.id_historia;
          this.embarazo_actual.id_embarazo=response.data.id_embarazo;
          this.embarazo_actual.id=response.data.id;
          this.id_historia=response.data.id_historia;

        }
      }

    }
  }
}
</script>