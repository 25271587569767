<template>
    <div class=" ">
        <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> Embarazo Actual</span> </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label">Ultima Menstruación: {{embarazo_actual.ultima_menstruacion}}</label>
                </div>
                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label">Semanas de gestación: {{embarazo_actual.semanas_gestacion}}</label>
                    
                </div>
                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label">Fecha probable de parto: {{embarazo_actual.fecha_parto}}</label>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import embarazoActualService from "../../../../services/embarazoActualService";
import {isEmpty} from "lodash";
import embarazoService from '../../../../services/embarazoService';
export default {
    props: ['idHistoria','idUsuario'],
    data(){
        return {
            id_historia:'',
            embarazo_actual:{
                id:'',
                id_historia:'',
                id_embarazo:'',
                
                ultima_menstruacion:'',
                semanas_gestacion:'',
                fecha_parto:'',
               
            },
            embarazo:{}
        }
    },
    async created(){
        const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
      this.embarazo=res_embarazo_actual.data;

      if(!isEmpty(res_embarazo_actual.data)){
        const response=await embarazoActualService.showByLast(this.embarazo.id);
        if(!isEmpty(response.data)){
          this.embarazo_actual.ultima_menstruacion=response.data.ultima_menstruacion;
          this.embarazo_actual.semanas_gestacion=response.data.semanas_gestacion;
          this.embarazo_actual.fecha_parto=response.data.fecha_parto;
          this.embarazo_actual.id_historia=response.data.id_historia;
          this.embarazo_actual.id_embarazo=response.data.id_embarazo;
          this.embarazo_actual.id=response.data.id;
          this.id_historia=response.data.id_historia;

        }
      }
             
        
    },
    watch: {
        idUsuario : async function(){
           const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
        this.embarazo=res_embarazo_actual.data;
        if(!isEmpty(res_embarazo_actual.data)){
          const response=await embarazoActualService.showByLast(this.embarazo.id);
          if(!isEmpty(response.data)){
            this.embarazo_actual.ultima_menstruacion=response.data.ultima_menstruacion;
            this.embarazo_actual.semanas_gestacion=response.data.semanas_gestacion;
            this.embarazo_actual.fecha_parto=response.data.fecha_parto;
            this.embarazo_actual.id_historia=response.data.id_historia;
            this.embarazo_actual.id_embarazo=response.data.id_embarazo;
            this.embarazo_actual.id=response.data.id;
            this.id_historia=response.data.id_historia;
          }
        }
        }
    }
}
</script>