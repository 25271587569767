import Service from './Service';
import { isEmpty } from 'lodash';
const resource = '/api/ambito';

export default {
    search(search = '', pagination = {}) {

        let url = `${resource}/search?`;

        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);

    },
    async list() {
        let url = `${resource}/index`;
        return Service.get(url);
    }
}