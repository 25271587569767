<template>
    <main>
        <div class="form-group mt-4">
            <label  class="form-label"><i class="fas fa-poll-h fa-fw"></i>Servicio al que se remite</label>
            <v-select-servicio v-model="remision.servicio" mode="object" 
             :validator="$v.remision.servicio"
             :class="{
                'is-invalid' : $v.remision.servicio.$error && $v.remision.servicio.$dirty
             }"  ></v-select-servicio>
             <span class="invalid-feedback" v-if="!$v.remision.servicio.required">
                Este campo es requerido
            </span>
        </div>
        
        <div class="form-group">
            <label  class="form-label"><i class="fas fa-university fa-fw"></i> Institución</label>
            <input  v-model="remision.institucion" class="form-control" :class="{'is-invalid':$v.remision.institucion.$error && $v.remision.institucion.$dirty}">
        </div>
    
        <div class="form-group">
            <label  class="form-label"><i class="far fa-comment-alt fa-fw"></i> Motivo </label>
            <div class="">
                <textarea  v-model="remision.motivo" class="form-control" :class="{'is-invalid':$v.remision.motivo.$error && $v.remision.motivo.$dirty}"></textarea>
            </div>
        </div>   

        <button class="btn btn-success btn-sm" @click="guardar()">
            <i class="fas fa-save"></i>&nbsp;Guardar
        </button>
    </main>
</template>
<script>
import vSelectServicio from "../../../../components/common/VSelectServicio";
import remisionService from '../../../../services/remisionService';
import {required} from "vuelidate/lib/validators";
import Toast from '../../../../components/common/utilities/toast';

export default {
    props:['idHistoria'],
    components: {vSelectServicio},
    data() {
        return{
            
            remision:{
                id_historia:'',
                institucion:'',
                motivo:'',
                servicio:{},
            },
        }
    },
    validations (){
        return {
           
            remision:{ 
                institucion:{required},
                servicio:{required},
                motivo:{required},
            }
            
        }
    },
    methods: {
        async guardar() {
            try {
                this.$v.$touch();
                if(this.$v.$invalid){
                    return
                }
               
                this.LoaderSpinnerShow();
                this.remision.id_historia=this.idHistoria;
                await remisionService.store(this.remision);
                this.limpiarCampos();
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                this.$emit('cargar-Remisiones');
            }catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos(){
            this.remision={};
        }
    }
}
</script>
