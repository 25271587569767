<template>
  <main>
    <div class="form-row mb-2 mt-2 border-bottom">
      <div class="col-6">
        <strong>Examen/Procedimiento</strong>
      </div>
      <div class="col-3">
        <strong>Fecha</strong>
      </div>
      <div class="col-3">
        <strong>Resultado</strong>
      </div>
    </div>
    <div class="form-row mb-1 mt-1" v-for="item in procedimientos" :key="item.id" :value="item">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 small">{{item.servicio.descripcion}}</div>
      <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6">
        <label class="d-none d-sm-block d-md-none small">Fecha</label>
        <input type="date" v-model="item.fecha" class="form-control form-control-sm">
      </div>
      <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6">
        <label class="d-none d-sm-block d-md-none small">Resultado</label>
        <input :type="item.tipo" v-model="item.resultado" class="form-control form-control-sm">
      </div>
    </div>
  </main>
</template>
<script>
import usuarioService from '../../../../../services/usuarioService';
import procedimientosResultadosService from "../../../../../services/procedimientosResultadosService";
import embarazoService from '../../../../../services/embarazoService';
import procedimientosEmbarazoService from '../../../../../services/procedimientosEmbarazoService';
import {isEmpty} from "lodash";

import {required} from "vuelidate/lib/validators";
import Toast from "@/components/common/utilities/toast";

export default {
  props: ['idHistoria','finalizada','idUsuario'],
  data() {
    return {
      usuario: {},
      procedimientos: [],
      items_procedimientos: [],
      item_procedimiento:{
        procedimiento:{},
        resultado:'',
        fecha:''
      },
      embarazo_actual: {},
    };
  },
  validations (){
    return {
      item_procedimiento:{
        procedimiento:{required},
        fecha:{required},
        resultado:{required},
      },
    }
  },
  methods: {
    async cargarUsuario(id) {
      const response = await usuarioService.show(id);
      this.usuario = response.data;

      const res_embarazo_actual= await embarazoService.showByLast(id);
      this.embarazo_actual=res_embarazo_actual.data;
    },

    async cargarProcedimientos(){

      const response = await procedimientosEmbarazoService.index();

      this.procedimientos = response.data.map(x => {
        return {
          ...x,
          fecha : '',
          resultado : '',
        }
      });

    },
    async save() {
      try {

        if(!this.procedimientos.filter(x => x.resultado !== '' && x.fecha !== '').length) return;

        let procedimientos_resultados = {
          id_embarazo:!isEmpty(this.embarazo_actual) && this.embarazo_actual.activo === 'S'?this.embarazo_actual.id:null,
          id_historia:this.idHistoria,
          procedimientos: this.procedimientos.filter(x => x.resultado !== '' && x.fecha !== '')
        };

        await procedimientosResultadosService.store(procedimientos_resultados);

        Toast.fire({
          icon: 'success',
          title: 'Examenes guardados con exíto'
        });

      } catch (e) {
        console.error(e);
        Toast.fire({
          icon: 'error',
          title: 'Ocurrio un error al guardar el reporte de examenes'
        });
      }
    }
  },
  async created() {
    await this.cargarUsuario(this.idUsuario);
    await this.cargarProcedimientos();

    const response = await procedimientosResultadosService.index({
      'id_historia' : this.idHistoria
    });

    response.data.forEach((x) => {
      let index = this.procedimientos.findIndex(y => parseInt(y.id) === parseInt(x.procedimiento.id));
      if(index !== -1){
        this.procedimientos[index].resultado = x.resultado;
        this.procedimientos[index].fecha = x.fecha;
      }
    });
  },
}
</script>