<template>
    <main>
        <div class="form-group row">
            <label  class="col-sm-6 col-form-label"><i class="fa fa-ruler-vertical"></i> Altura Uterina <span class="text-danger">(Cm)</span></label>
            <div class="col-sm-6">
                <input type="number" v-model="examen_fisico_embarazo.altura_uterina" class="form-control" :class="{'is-invalid':$v.examen_fisico_embarazo.altura_uterina.$error && $v.examen_fisico_embarazo.altura_uterina.$dirty}">
            </div>
        </div>
            <div class="form-group row">
            <label  class="col-sm-6 col-form-label"><i class="fa fa-thermometer"></i> FCF <span class="text-danger">(lat/min)</span></label>
            <div class="col-sm-6">
                <input type="number" v-model="examen_fisico_embarazo.fcf" class="form-control"  :class="{'is-invalid':$v.examen_fisico_embarazo.fcf.$error && $v.examen_fisico_embarazo.fcf.$dirty}">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-6 col-form-label"><i class="fa fa-thermometer"></i> Presentacion <span class="text-danger">(ºC)</span></label>
            <div class="col-sm-6">
            <select v-model="examen_fisico_embarazo.presentacion" class="form-control" :class="{'is-invalid':$v.examen_fisico_embarazo.presentacion.$error && $v.examen_fisico_embarazo.presentacion.$dirty}">
                <option :value="presentacion" v-for="presentacion in presentaciones" :key="presentacion">{{presentacion}}</option>
            </select>
            </div>
        </div>
            <div class="form-group row">
            <label  class="col-sm-6 col-form-label"><i class="fa fa-lungs"></i> Movimientos Fetales <span class="text-danger">(r/m)</span></label>
            <div class="col-sm-6">
                <input type="number" v-model="examen_fisico_embarazo.movimiento_fetal" class="form-control" :class="{'is-invalid':$v.examen_fisico_embarazo.movimiento_fetal.$error && $v.examen_fisico_embarazo.movimiento_fetal.$dirty}">
            </div>
        </div>
    </main>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import examenFisicoEmbarazoService from "../../../../services/examenFisicoEmbarazoService";
import {isEmpty} from 'lodash';
export default {
    props:['idHistoria','finalizada'],
    data(){
        return {
            examen_fisico_embarazo:{
                altura_uterina:'',
                fcf:'',
                movimiento_fetal:'',
                presentacion:'',
                id_examen_fisico_embarazo:''
            },
            presentaciones:['CEFALICO','PELVICA','TRANSVERSAL']
        }
    },
    validations (){
        return {
            examen_fisico_embarazo:{
                altura_uterina:{required},
                fcf:{required},
                movimiento_fetal:{required},
                presentacion:{required},
            }
        }
    },
    methods:{
        retornar(){
            this.$v.examen_fisico_embarazo.$touch();
            if(this.$v.examen_fisico_embarazo.$invalid){
                return false;
            }else{
                return this.examen_fisico_embarazo;
            }
        }
    },
    watch:{
        finalizada: async function (value){
            if(value==='N'){
                const response = await examenFisicoEmbarazoService.showByHc(this.idHistoria);
                if(!isEmpty(response.data)){
                    this.examen_fisico_embarazo.fcf=response.data.fcf;
                    this.examen_fisico_embarazo.altura_uterina=response.data.altura_uterina;
                    this.examen_fisico_embarazo.movimiento_fetal=response.data.movimiento_fetal;
                    this.examen_fisico_embarazo.presentacion=response.data.presentacion;
                    this.examen_fisico_embarazo.id_examen_fisico_embarazo=response.data.id;
                }
            } 
        }
    },
    async created(){
        if(this.finalizada==='N'){
            const response = await examenFisicoEmbarazoService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                this.examen_fisico_embarazo.fcf=response.data.fcf;
                this.examen_fisico_embarazo.altura_uterina=response.data.altura_uterina;
                this.examen_fisico_embarazo.movimiento_fetal=response.data.movimiento_fetal;
                this.examen_fisico_embarazo.presentacion=response.data.presentacion;
                this.examen_fisico_embarazo.id_examen_fisico_embarazo=response.data.id;

            }
        }
    }
}
</script>