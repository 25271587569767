<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-diagnoses"> </i> Examen Fisico Embarazo</span> </div>
            <div class="card-body">
        <div class="form-row">
            <div class="form-group col-md-4">
                <h6 > Altura Uterina : {{examen_fisico_embarazo.altura_uterina}} </h6>
            </div>
            <div class="form-group col-md-4">
                <h6 > FCF : {{examen_fisico_embarazo.fcf}} </h6>
            </div>
             <div class="form-group col-md-4">
                <h6 > Presentacion : {{examen_fisico_embarazo.presentacion}} </h6>
            </div>
            <div class="form-group col-md-4">
                <h6 > Movimientos Fetales : {{examen_fisico_embarazo.movimiento_fetal}} </h6>
            </div>
        </div>
            </div>
        </div>
        
    </main>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import examenFisicoEmbarazoService from "../../../../services/examenFisicoEmbarazoService";
import {isEmpty} from 'lodash';
export default {
    props:['idHistoria','finalizada'],
    data(){
        return {
            examen_fisico_embarazo:{
                altura_uterina:'',
                fcf:'',
                movimiento_fetal:'',
                presentacion:'',
                id_examen_fisico_embarazo:''
            },
            presentaciones:['CEFALICO','PELVICA','TRANSVERSAL']
        }
    },
    validations (){
        return {
            examen_fisico_embarazo:{
                altura_uterina:{required},
                fcf:{required},
                movimiento_fetal:{required},
                presentacion:{required},
            }
        }
    },
    async created(){
       
            const response = await examenFisicoEmbarazoService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                this.examen_fisico_embarazo.fcf=response.data.fcf;
                this.examen_fisico_embarazo.altura_uterina=response.data.altura_uterina;
                this.examen_fisico_embarazo.movimiento_fetal=response.data.movimiento_fetal;
                this.examen_fisico_embarazo.presentacion=response.data.presentacion;
                this.examen_fisico_embarazo.id_examen_fisico_embarazo=response.data.id;

            }
        
    }
}
</script>