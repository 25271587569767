import LoginService from './loginService';
import SanctumService from './sanctumService';
import PucService from './pucService';
import EpsService from './epsService';
import usuarioService from './usuarioService';
import servicioService from './servicioService';
import ambitoService from './ambitoService';
import sedeService from './sedeService';
import diagnosticoService from './diagnosticoService';
import causaExternaService from './causaExternaService';
import finalidadService from './finalidadService';
import tipoDiagnosticoService from './tipoDiagnosticoService';
import facturaService from './facturaService';
import tarifaService from './tarifaService';
import contratosEpsService from './contratosEpsService';

const services = {
    login: LoginService,
    sanctum: SanctumService,
    puc: PucService,
    'eps': EpsService,
    'usuario': usuarioService,
    'servicios': servicioService,
    'ambito': ambitoService,
    'sede': sedeService,
    'diagnostico': diagnosticoService,
    'causa-externa': causaExternaService,
    'finalidad': finalidadService,
    'tipo-diagnostico': tipoDiagnosticoService,
    'factura': facturaService,
    'tarifa' : tarifaService,
    'contrato-eps' : contratosEpsService,
};

export default  {
    get: name => services[name]
};




