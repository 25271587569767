import Service from './Service';
import {isEmpty} from 'lodash';

const resource = '/api/puc';

export default {
    async mostrar(codigo) {
      let url = `${resource}/show/${codigo}`;
      return Service.get(url);
    },
    async naturaleza_list() {
        let url = `${resource}/list/naturaleza`;
        return Service.get(url);
    },
    async tipo_cuenta_list(){
        let url = `${resource}/list/tipo_cuenta`;
        return Service.get(url);
    },
    async buscarCuentasSuperiores(search, tipo = ""){

        let url = `${resource}/data-source?s=${search}`;

        if(tipo !== ""){
            url = `${url}&tipo=${tipo}`;
        }

        return Service.get(url);
    },
    guardar(cuenta){
        let url = `${resource}/guardar`;
        return Service.post(url, cuenta);
    },
    async getCuentas(search = '', pagination = {}, filters = {}){

        let url = `${resource}/index?`;

        let filters_string = '';

        if (filters !== undefined && filters !== null) {

            for (let key in filters) {
                if (filters_string != "") {
                    filters_string += "&";
                }
                filters_string += key + "=" + filters[key];
            }
        }

        url += search !== '' ? `search=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : '';
        url += `&${filters_string}`;

        return Service.get(url);
    },
    async getClassAcount(code){
        let url = `${resource}/get/class-acount/${code}`;
        return Service.get(url);
    },
    async searchRetenciones(search = ''){
        let url = `api/retencion/search?s=${search}`;
        return Service.get(url);
    },
    async getAcountById(id){
        let url = `${resource}/get/${id}`;
        return Service.get(url);
    }
}
