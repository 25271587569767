<template>
  <div>
    <div class="row no-gutters">
      <div v-for="item in $v.partes_revision.$each.$iter" :key="item.id" class="form-group col-4 pl-2 pr-2">
        <label class="mb-0">{{ item.nombre.$model }}</label>
        <textarea v-model="item.detalle.$model" :class="{'is-invalid':item.detalle.$error}" class="form-control form-control-sm">
      </textarea>
      </div>
    </div>
  </div>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import partesRevisionSistemasService from "../../../../services/partesRevisionSistemasService";
import historiaRevisionSistemasService from "../../../../services/historiaRevisionSistemasService";
import {isEmpty} from 'lodash';
import Toast from '../../../../components/common/utilities/toast';

export default {
  props: ['idHistoria', 'finalizada', 'sexo'],
  data() {
    return {
      control: false,
      partes_revision: [],
    }
  },
  validations() {
    return {
      partes_revision: {
        $each: {
          nombre: {required},
          detalle: {required}
        }
      }
    }
  },
  methods: {
    async save() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) return false;

        let revision = {
          id_historia: this.idHistoria,
          partes: this.partes_revision,
        }

        this.LoaderSpinnerShow();
        if (this.control) {
          await historiaRevisionSistemasService.update(revision);
          this.control = true;
        } else {
          await historiaRevisionSistemasService.store(revision);
          this.control = true;
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con exito'
        });

      } catch (error) {
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'error',
          title: 'Ocurrio un error al procesar la solicitud'
        });
      }

    },
    async cargarPartesRevision() {
      const response = await partesRevisionSistemasService.index({
        sexo : this.sexo
      });
      this.partes_revision = response.data.map(x => {
        return {
          ...x,
          detalle: ''
        }
      });
    },
  },
  watch: {
    finalizada: async function (value) {
      if (value === 'N') {
        const response = await historiaRevisionSistemasService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
          this.control = true;
          response.data.forEach(x => {
            let i = this.partes_revision.findIndex(p => parseInt(p.id) === parseInt(x.id_parte_revision));
            if (i !== -1) this.partes_revision[i].detalle = x.detalle;
          });

        }
      }
    }
  },
  async created() {
    this.cargarPartesRevision();
    if (this.finalizada === 'N') {
      const response = await historiaRevisionSistemasService.showByHc(this.idHistoria);
      if (!isEmpty(response.data)) {
        this.control = true;
        response.data.forEach(x => {
          let i = this.partes_revision.findIndex(p => parseInt(p.id) === parseInt(x.id_parte_revision));
          if (i !== -1) this.partes_revision[i].detalle = x.detalle;
        });
      }
    }
  }
}
</script>