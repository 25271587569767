<template>
    <main>
        <!-- Modal -->
        <modalFormulasComponent :formulas="formulas" :usuario="usuario" ></modalFormulasComponent>
        <div class=" " >
            <div class=" ">
                <div class="form-group row">
                    <div class="col-12 d-flex justify-content-end">
                        
                        <button @click="historialFormulas();" type="button" class="btn btn-outline-primary"><i class="fas fa-eye"></i>&nbsp;Ver Formula</button>
                    </div>
                </div>
            
                <div class="form-group mt-2">
                    <label  class="form-label">Servicio / Medicamento </label>  
                    <v-select-servicio v-model="item.servicio" mode="object" ></v-select-servicio>
                </div><br>
                <div class="form-row">
                    <div class="form-group col-md-2 "  >
                        <label class="form-label" >Dosis</label>
                        <input type="number" class="form-control" v-model="item.dosis" :class="{'is-invalid':$v.item.dosis.$error && $v.item.dosis.$dirty}">
                    </div>
                    <div class="form-group col-md-2">
                        <label class="form-label">Frecuencia</label>
                        <input type="number" v-model="item.frecuencia" class="form-control" :class="{'is-invalid':$v.item.frecuencia.$error && $v.item.frecuencia.$dirty}">
                    </div>
                    <div class="form-group col-md-2">
                        <label class="form-label">Unidad de tiempo</label>
                        <select class="form-control" v-model="item.unidad">
                            <option value="horas" >Horas</option>
                            <option value="dias">Dias</option>
                        </select>
                    </div>
                     
                    <div class="form-group col-md-2">
                        <label class="form-label">Duración</label>
                        <input type="number" v-model="item.duracion" class="form-control" :class="{'is-invalid':$v.item.duracion.$error && $v.item.duracion.$dirty}">
                    </div>
                    <div class="form-group col-md-2">
                        <label class="form-label">Total</label>
                        <input type="number" class="form-control" v-model="item.total" :class="{'is-invalid':$v.item.total.$error && $v.item.total.$dirty}">
                    </div>
                    <div class="mt-4 py-2 col-md-2  ">
                        
                        <button class="btn btn-primary " @click="agregarServicio()">
                            <i class="fas fa-plus"></i>&nbsp;Agregar
                        </button>
                    </div>
                </div>
               
                <div class="table-responsive">
                    <table class="table table-striped">
                        <tr class="table-primary">
                            <th>Codigo</th>
                            <th>Servicio</th>
                            <th>Posologia</th>
                            
                            <th>Total</th>
                            <th></th>
                        </tr>
                        <tr v-for="item in items" :key="item.id" >
                            <td>{{item.servicio.codigo}}</td>
                            <td>{{ item.servicio.descripcion }}</td>
                            <td>{{ item.dosis }} cada {{ item.frecuencia }}
                                {{ item.unidad }} Por {{ item.duracion }} dias 
                            </td>
                            <td>{{ item.total }}</td>
                            
                            <td>
                                <button class="btn btn-outline-danger"  @click="removerServicio()">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>                                
                    </table>
                </div>
                <div class="form-group">
                    <label  class="form-label">Observaciones </label>
                    <div class="">
                        <textarea  v-model="observaciones" class="form-control" :class="{'is-invalid':$v.observaciones.$error && $v.observaciones.$dirty}"></textarea>
                    </div>
                </div>
                <button class="btn btn-success" @click="guardar()">
                    <i class="fas fa-save"></i>&nbsp;Guardar
                </button>
            </div>
        </div>
    </main>
</template>
<script>
import vSelectServicio from "../../../../components/common/VSelectServicio";
import formulaMedicaService from '../../../../services/formulaMedicaService';
import modalFormulasComponent from './modalFormulasComponent';
import $ from 'jquery';
import {required} from "vuelidate/lib/validators";
import {isEmpty} from 'lodash';
import Toast from '../../../../components/common/utilities/toast';

export default {
    props:['idHistoria','usuario'],
    components: {vSelectServicio,modalFormulasComponent},
     
    data() {
        return{
            observaciones:'',
            item:{
                servicio:{},
                dosis:'',
                frecuencia:'',
                duracion:'',
                total:'',
                unidad:'horas',
            },
            items: [],
            formulas: [],
        }
    },
    validations (){
        return {
            observaciones:{required},
            item:{ 
               dosis:{required},
               frecuencia:{required},
               duracion:{required},
               total:{required},
            }
            
        }
    },
    methods: {
        agregarServicio() {
            this.$v.item.$touch();
            if (this.$v.item.$invalid) {
                return;
            }
            this.items.push({...this.item});
        },
        removerServicio(index) {
            this.items.splice(index, 1);
        },
        async guardar() {
            try {
                this.$v.$touch();
                if (isEmpty(this.items)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe agregar por lo menos un servicio'
                    });
                    return;
                }
                if(this.$v.$invalid){
                    return
                }
                const formula = {
                    id_historia: this.idHistoria,
                    observaciones: this.observaciones,
                    item_formulas: this.items,
                        
                };
                
                this.LoaderSpinnerShow();
                await formulaMedicaService.store(formula);
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

                this.limpiarCampos();

            }catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async historialFormulas() {
            $('#modalFormula').modal('show');
            const response = await formulaMedicaService.show(this.idHistoria);
            this.formulas = response.data; 
        },
        limpiarCampos(){
            this.observaciones='';
            this.items=[];
            this.item={};
            this.servicios={};
        }
    }
}
</script>
