<template>
    <main>
        <!-- Modal -->
        <modalOrdenesComponent :ordenes="ordenes" :usuario="usuario"></modalOrdenesComponent>
        <div class=" " >
            <div class="">
                <div class="form-group row">
                    <div class="col-12 d-flex justify-content-end">    
                        <button @click="historialOrdenes();" type="button" class="btn btn-outline-primary"><i class="fas fa-eye"></i>&nbsp;Ver Ordenes</button>
                    </div>
                </div>
                <div class="form-group mt-2">
                    <label  class="form-label">Procedimiento / Examen</label>
                    <v-select-servicio v-model="item.servicio" mode="object" ></v-select-servicio>
                </div>
                <div class="form-row">
                <div class="form-group col-md-4">
                    <label  class="form-label">Cantidad</label>
                    <input  v-model="item.cantidad" class="form-control" :class="{'is-invalid':$v.item.cantidad.$error && $v.item.cantidad.$dirty}">
                </div>
                 <div class="form-group col-md-6">
                    <label  class="form-label">Observacion </label>
                    <input type="text" class="form-control"  v-model="item.observacion" :class="{'is-invalid':$v.item.observacion.$error && $v.item.observacion.$dirty}" >
                </div>
                <div class=" mt-4 py-2 col-md-2  ">
                    <button class="btn btn-primary " @click="agregarServicio()">
                        <i class="fas fa-plus"></i>&nbsp;Agregar
                    </button>
                </div>
                </div>
                
                <div class="table-responsive">
                    <table class="table table-striped">
                        <tr class="table-primary">
                            <th>Codigo</th>
                            <th>Servicio</th>
                            <th>Cantidad</th>
                            <th>Observacion</th>
                            <th></th>
                        </tr>
                        <tr v-for="item in items" :key="item.id" >
                            <td>{{item.servicio.codigo}}</td>
                            <td>{{ item.servicio.descripcion }}</td>
                            <td>{{ item.cantidad }}</td>
                            <td>{{ item.observacion }}</td>
                            <td>
                                <button class="btn btn-outline-danger"  @click="removerServicio()">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>                                
                    </table>
                </div>
                <div class="form-group">
                    <label  class="form-label">Observaciones </label>
                    <div class="">
                        <textarea  v-model="observaciones" class="form-control" :class="{'is-invalid':$v.observaciones.$error && $v.observaciones.$dirty}"></textarea>
                    </div>
                </div>
                <button class="btn btn-success " @click="guardar()">
                    <i class="fas fa-save"></i>&nbsp;Guardar
                </button>
            </div>
        </div>
    </main>
</template>
<script>
import vSelectServicio from "../../../../components/common/VSelectServicio";
import ordenClinicaService from '../../../../services/ordenClinicaService';
import modalOrdenesComponent from './modalOrdenesComponent';
import $ from 'jquery';
import {required} from "vuelidate/lib/validators";
import {isEmpty} from 'lodash';
import Toast from '../../../../components/common/utilities/toast';

export default {
    props:['idHistoria','usuario'],
    components: {vSelectServicio,modalOrdenesComponent},
    data() {
        return{
            id_historia:'',
            observaciones:'',
            item:{
                cantidad:'',
                observacion:'',
                servicio:{},
            },
            items: [],
            ordenes:[],
        }
    },
    validations (){
        return {
            observaciones:{required},
            item:{ 
               cantidad:{required},
               observacion:{required},
            }
            
        }
    },
    methods: {
        agregarServicio() {
            this.$v.item.$touch();
            if (this.$v.item.$invalid) {
                return;
            }
            this.items.push({...this.item});
        },
        removerServicio(index) {
            this.items.splice(index, 1);
        },
        async guardar() {
            try {
                this.$v.$touch();
                if (isEmpty(this.items)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe agregar por lo menos un servicio'
                    });
                    return;
                }
                if(this.$v.$invalid){
                    return
                }
                const orden = {
                        id_historia: this.idHistoria,
                        observaciones: this.observaciones,
                        item_ordenes: this.items,
                    };
                this.LoaderSpinnerShow();
                await ordenClinicaService.store(orden);
                this.limpiarCampos();
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            }catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async historialOrdenes() {
            $('#modalOrden').modal('show');
            const response = await ordenClinicaService.show(this.idHistoria);
            this.ordenes = response.data;
            //console.log(this.orden);
        },
        limpiarCampos(){
            this.observaciones='';
            this.item={};
            this.items=[];
        }
    }
}
</script>
