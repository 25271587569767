<template>
  <v-select
    label="label"
    :options="diagnosticos"
    :reduce="dx => mode === 'object' ? dx : dx.IDDIAG"
    @search="debounceSearch"
    @input="setChange"
    v-model="selected"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input
        class="vs__search"
        v-bind="attributes"
        v-on="events"
      />
    </template>
  </v-select>
</template>

<script>
import {debounce} from 'lodash';
import VSelect from 'vue-select';
import ServiceFactory from "../../services/ServiceFactory";
const DxService = ServiceFactory.get("diagnostico");

export default {
  props: ["value", "mode"],
  components : {VSelect},
  data() {
    return {
      selected : this.value,
      diagnosticos: [],
    };
  },
  created() {
    this.debounceSearch = debounce(this.search, 300);
  },
  watch : {

  },
  methods: {
    async search(search, loading) {
      if(search === '')
      {
          return;
      }

      loading(true);
      let response = await DxService.search(search);
      this.diagnosticos = response.data;
      loading(false);
    },
    setChange(value){
      this.$emit('input', value);
    }
  },
};
</script>
