import Service from './Service';
import {isEmpty} from 'lodash';

const resource = '/api/eps';

export default {
    search(search = '', pagination = {}) {

        let url = `${resource}/search?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        return Service.get(url);

    },
    async list(search = '', pagination = {}){
        let url = `${resource}/list?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        return Service.get(url);
    },
    async get(id){
        let url = `${resource}/show/${id}`;
        return Service.get(url);
    },
    async update(eps) {
        let url = `${resource}/update`;
        return Service.post(url, eps);
    },
    store(eps){
        return Service.post(`${resource}/store`, eps);
    },
    isUnique(code){
        return Service.get(`${resource}/is-unique/${code}`);
    },
    listEpsNomina(search = '', pagination = {}){
        let url = `${resource}/listarEpsNomina?`;
        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&per_page=${pagination.per_page}&page=${pagination.page}` : '';

        return Service.get(url);
    },
    guardarEpsNomina(contract){
        const url = `${resource}/guardarEpsNomina`;
        return Service.post(url, contract);
    },
}
