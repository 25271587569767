import Service from './Service';
import {isEmpty} from 'lodash';

const resource = '/api/factura';

export default {
    async save(factura){
        let url = `${resource}/store`;
        return Service.post(url, factura);
    },
    async update(factura){
      return Service.put(`${resource}/event-invoice/update`, factura);
    },
    async saveEpsIvoice(factura){
        let url = `${resource}/store-eps-invoice`;
        return Service.post(url, factura);
    },
    async index(search = '', pagination = {}){

        let url = `${resource}/invoice-list?`;

        url += search !== '' ? `&s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        return Service.get(url);
    },
    async sendInvoiceEps(factura){
        let url = `/api/facturacion-electronica/send-invoice-eps/${factura}`;
        return Service.get(url);
    },
    async sendInvoice(factura){
        let url = `/api/facturacion-electronica/send/${factura}`;
        return Service.get(url);
    },
    eventInvoiceList(search = '', pagination = {}){

        let url = `${resource}/event-invoice/?`;
        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&per_page=${pagination.per_page}&page=${pagination.page}` : '';

        return Service.get(url);
    },
    showEventInvoice(id){
        const url = `${resource}/event-invoice/show/${id}`;
        return Service.get(url);
    },
    report(format='xls', params = {}){

        let url = `${resource}/report/relationship`;

        return Service.get(url, {
            responseType: 'blob',
            params : {
                ...params,
                format : format
            }
        });

    },
    massChangeState(payload){
        return Service.post(`${resource}/actions/eps-invoice/mass-change-state`, payload);
    }
}
