<template>
    <main>
        <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> Reporte Examenes</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-sm">
                        <tr>
                            <th>Procedimiento</th>
                            <th>Fecha</th>
                            <th>Resultado</th>
                        </tr>
                        <tr v-for="item in items_procedimientos" :key="item.id" >
                            <td>{{item.procedimiento.servicio.descripcion}}</td>
                            <td>{{ item.fecha }}</td>
                            <td>{{ item.resultado }}</td>
                        </tr>                                
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import usuarioService from '../../../../../services/usuarioService';
import procedimientosResultadosService from "../../../../../services/procedimientosResultadosService";
import embarazoService from '../../../../../services/embarazoService';
import procedimientosEmbarazoService from '../../../../../services/procedimientosEmbarazoService';
import {isEmpty} from "lodash";

import {required} from "vuelidate/lib/validators";

export default {
    props: ['idHistoria','finalizada','idUsuario'],
    data() {
        return {
            usuario: {},
            procedimientos: [],
            items_procedimientos: [],
            item_procedimiento:{
                procedimiento:{},
                resultado:'',
                fecha:''
            },
            embarazo_actual: {},
        };
    },
    validations (){
        return {
            item_procedimiento:{
                procedimiento:{required},
                fecha:{required},
                resultado:{required},
            },
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;

            const res_embarazo_actual= await embarazoService.showByLast(id);
            this.embarazo_actual=res_embarazo_actual.data;
        },

        async cargarProcedimientos(){
            
            const response = await procedimientosEmbarazoService.index();

            this.procedimientos = response.data;
        },     
    },
    async created() {
        //if(isEmpty(this.idUsuario)) return ;
        await this.cargarUsuario(this.idUsuario);
        this.cargarProcedimientos();
        const response=await procedimientosResultadosService.showByIdEmbarazo(this.embarazo_actual.id);
        if(!isEmpty(response.data)){
            this.items_procedimientos = response.data.map(x=>{
                return {
                    id:x.id,
                    fecha:x.fecha,
                    resultado:x.resultado,
                    procedimiento:x.procedimiento
                }
            });        
        } 
    },
     watch: {
        idUsuario : async function(){
            await this.cargarUsuario(this.idUsuario);
            this.cargarProcedimientos();
            const response=await procedimientosResultadosService.showByIdEmbarazo(this.embarazo_actual.id);
            if(!isEmpty(response.data)){
                this.items_procedimientos = response.data.map(x=>{
                    return {
                        id:x.id,
                        fecha:x.fecha,
                        resultado:x.resultado,
                        procedimiento:x.procedimiento
                    }
                });        
            } 
        }
    }
}
</script>