import Service from './Service';
import {isEmpty} from 'lodash';

const resource = '/api/sede';

export default {
    getSedeByCode(codigo) {
        let url = `${resource}/get?by=code&codigo=${codigo}`;
        return Service.get(url);
    },
    index(search = '', pagination = {}, sexo = '') {

        let url = `${resource}?`;

        url += search !== '' ? `s=${search}` : '';
        url += sexo !== '' ? `&sexo=${sexo}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);

    },
    dataSource(search = ''){
        let url = `${resource}/data-source-sede${search}`;
        return Service.get(url);
    }
}
